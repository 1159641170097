[data-component='Dashboard'] {
    font-family: 'Comic Neue', sans-serif;
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
    margin: 0;
    padding: 0;
  
    .spacer {
      flex-grow: 1;
    }
  
    .grid-container {
      display: grid;
      grid-template-columns: 60% 40%;
      height: 100%;
      
    }
  
    .column {
      padding: 10px;
      overflow-y: auto;
      position: relative; /* Ensure positioning context for absolute positioning */
    }
  
    .column-1 {
      display: flex;
      flex-direction: column;
      height: 100vh; /* Full viewport height */
      overflow: hidden; /* Prevent content overflow */
    }

    .content-top {
        display: flex;
        align-items: center;
        justify-content: center; /* Center the content horizontally */
        padding: 8px 16px;
        margin-top: 20px;
        min-height: 20px;
        text-align: center; /* Center the text */
    
        .content-title {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center; /* Center the text */
          gap: 12px;
          
          img {
            width: 50px;
            height: auto;
            display: block;
          }
        }
    
        .rainbow-text {
        // margin-left: 2%;
        margin-bottom: 5%;
        background: linear-gradient(to right, #ff6600 10%, #800000 70%, #cc0000 100%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 2vw;
        font-family: 'Comic Neue', sans-serif;
        text-align: center;
          color: #000;
        text-align: center;
        background: linear-gradient(90deg, red, orange, yellow, green, blue, indigo, violet);
    
      // background: -webkit-linear-gradient(right, #ff6600 0%, #800000 25%, #cc0000 50%, yellow 100%) repeat;
        -webkit-background-clip: text;
        -ms-background-clip: text;
        -moz-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        -ms-text-fill-color: transparent;
        -moz-text-fill-color: transparent;
        text-fill-color: transparent;
        -webkit-animation-name: shimmer;
        -webkit-animation-duration: 50s;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;
    
    }
      }
      
      .content-block-title {
        flex-shrink: 0;
        padding-top: 4px;
        padding-bottom: 4px;
        position: relative;
      }
      .content-block-body {
        color: #6e6e7f;
        position: relative;
        flex-grow: 1;
        padding: 1px 0px;
        padding-top: 2px;
        line-height: 1.2em;
        overflow: auto;
        &.full {
          padding: 0px;
        }
      }

      .column-2 {
        display: flex;
        flex-direction: column;
        height: 80%;
        padding: 10px;
        box-sizing: border-box; /* Include padding in height calculation */
        overflow: hidden;
        margin-right: 10px;
        justify-content: center; /* Center vertically */
        align-items: center; /* Center horizontally */
        margin-bottom: 10px;
      }

      .table-container {
        font-size: 2px; /* Adjust the font size as needed */
        max-height: 80%; /* Set a fixed height for the table container */
        overflow-y: auto; /* Enable vertical scrolling */
      }
      
      .table-container th,
      .table-container td {
        font-size: 12px;
        padding: 4px; /* Adjust padding as needed */
      }

      .table-container td .items-cell {
        max-height: 100px; /* Set a fixed height for the items cell */
        overflow-y: auto; /* Enable vertical scrolling */
        display: block; /* Ensure the content is block-level */
      }

    }