[data-component='StorytimeStacy'] {
  font-family: 'Comic Neue', sans-serif;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  margin: 0;
  padding: 0;

  .spacer {
    flex-grow: 1;
  }

  .grid-container {
    display: grid;
    grid-template-columns: 60% 40%;
    height: 100%;
    
  }

  .column {
    padding: 20px;
    overflow-y: auto;
    position: relative; /* Ensure positioning context for absolute positioning */
  }

  .column-1 {
    display: flex;
    flex-direction: column;
    height: 100vh; /* Full viewport height */
    overflow: hidden; /* Prevent content overflow */
  }

  // .connect {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   width: 100%;
  //   margin: 20px 0;
  
  //   /* Style the button */
  //   // Button {
  //   //   width: 80%; /* Make button wider */
  //   //   height: 50px; /* Increase height */
  //   //   font-size: 1.2em; /* Larger text */
  //   //   border-radius: 25px; /* Rounded corners */
  //   //   text-transform: uppercase; /* Optional: make text uppercase */
  //   //   font-weight: bold; /* Optional: make text bold */
  //   // }
  // }

  // .manual-vad {
  //   margin-bottom: 20px;
  //   align-self: flex-start; /* Ensure left alignment */
  //   width: auto; /* Allow content width */tom: 20px; /* Add some margin from the bottom */
  // }



  .centre-content {
    flex: 3; /* Occupies more space */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .content-actions {
    flex: 1;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-top: 10px; 
    margin-bottom: 10px; 
    margin-left: 200px; 
    margin-right: 200px; 
  }

  .centre-image-container {
    display: flex; /* Enable flex display */
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;

    .centre-image {
      width: 50%; /* Control image width */
      height: auto;
      object-fit: contain; /* Maintain aspect ratio */
      position: relative; /* Position relatively */
      /* Remove flex properties from image since it's not a container */
    }
  

  .visualization {
    position: absolute; /* Position absolutely within the .centre-image-container */
    bottom: 0; /* Position 4px from the bottom */
    right: 0; /* Position 8px from the right */
    display: flex;
    padding: 4px;
    border-radius: 16px;
    z-index: 10;
    gap: 2px;
  
    .visualization-entry {
      display: flex;
      align-items: center;
      height: 40px;
      width: 100px;
      gap: 4px;
  
      &.client {
        color: #0099ff;
      }
  
      &.server {
        color: #009900;
      }
  
      canvas {
        width: 100%;
      }
    }
  }
} 
  
  

  
  // .centre-image {
  //   width: 100%;
  //   height: 80%; // Increased from previous value
  //   border-radius: 10px;
  //   max-height: 65vh; // Increased max-height
  //   object-fit: contain;
  // }

  .centre-image-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 50%;
    margin: 0 auto;
    padding: 20px;
    overflow-y: auto;
    max-height: 200px;
    background-color: #f5f5f5;
    border-radius: 10px;
  
    h1 {
      margin: 0;
      font-size: 1.2em;
      line-height: 1.4;
    }
  }

  .content-top {
    display: flex;
    align-items: center;
    justify-content: center; /* Center the content horizontally */
    padding: 8px 16px;
    min-height: 80px;
    text-align: center; /* Center the text */

    .content-title {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center; /* Center the text */
      gap: 12px;
      
      img {
        width: 50px;
        height: auto;
        display: block;
      }
    }

    .rainbow-text {
    // margin-left: 2%;
    margin-bottom: 5%;
    background: linear-gradient(to right, #ff6600 10%, #800000 70%, #cc0000 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 2vw;
    font-family: 'Comic Neue', sans-serif;
    text-align: center;
      color: #000;
    text-align: center;
    background: linear-gradient(90deg, red, orange, yellow, green, blue, indigo, violet);

  // background: -webkit-linear-gradient(right, #ff6600 0%, #800000 25%, #cc0000 50%, yellow 100%) repeat;
    -webkit-background-clip: text;
    -ms-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -ms-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    text-fill-color: transparent;
    -webkit-animation-name: shimmer;
    -webkit-animation-duration: 50s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;

}
  }
  
  .content-block-title {
    flex-shrink: 0;
    padding-top: 16px;
    padding-bottom: 4px;
    position: relative;
  }
  .content-block-body {
    color: #6e6e7f;
    position: relative;
    flex-grow: 1;
    padding: 1px 0px;
    padding-top: 4px;
    line-height: 1.2em;
    overflow: auto;
    &.full {
      padding: 0px;
    }
  }
  
    // .content-right {
    //   width: 600px;
    //   flex-shrink: 0;
    //   display: flex;
    //   flex-direction: column;
    //   margin-left: 24px;
    //   gap: 24px;
    //   overflow: hidden;
    //   & > div {
    //     border-radius: 16px;
    //     flex-grow: 1;
    //     flex-shrink: 0;
    //     overflow: hidden;
    //     position: relative;
    //     .content-block-title {
    //       position: absolute;
    //       display: flex;
    //       align-items: center;
    //       justify-content: center;
    //       line-height: 2em;
    //       top: 16px;
    //       left: 16px;
    //       padding: 4px 16px;
    //       background-color: #fff;
    //       border-radius: 1000px;
    //       min-height: 32px;
    //       z-index: 9999;
    //       text-align: center;
    //       white-space: normal;
    //       &.bottom {
    //         top: auto;
    //         bottom: 16px;
    //         right: 16px;
          
    //       }
    //     }
    //   }
    //   & > div.kv {
    //     height: 250px;
    //     max-height: 250px;
    //     white-space: pre;
    //     background-color: #ececf1;
    //     .content-block-body {
    //       padding: 16px;
    //       margin-top: 56px;
          
    //     }
   
    //   }
    // }

  // .content-logs {
  //   flex-grow: 1;
  //   display: flex;
  //   flex-direction: column;
  //   overflow: hidden;
  //   & > div {
  //     flex-grow: 1;
  //   }
  //   & > 
 
    & > div.events {
      overflow: hidden;
    }
    .events {
      border-top: 1px solid #e7e7e7;
    }

  .column-2 {
    display: flex;
    flex-direction: column;
    height: 80%;
    padding: 20px;
    box-sizing: border-box; /* Include padding in height calculation */
    overflow: hidden;
    margin-right: 20px;
    justify-content: center; /* Center vertically */
    align-items: center; /* Center horizontally */
  }

  .conversation {
    display: flex;
    flex-shrink: 0;
    width: 100%;
    overflow: auto;
    height: 95%;
    min-height: 0;
    max-height: auto;
    border-top: 1px solid #e7e7e7;
    overflow-y: auto;
    padding: 20px;
  }
  
  .conversation-item {
    position: relative;
    display: flex;
    gap: 16px;
    margin-bottom: 16px;
    &:not(:hover) .close {
      display: none;
    }
    .close {
      position: absolute;
      top: 0px;
      right: -20px;
      background: #aaa;
      color: #fff;
      display: flex;
      border-radius: 16px;
      padding: 2px;
      cursor: pointer;
      &:hover {
        background: #696969;
      }
      svg {
        stroke-width: 3;
        width: 12px;
        height: 12px;
      }
    }
    .speaker {
      position: relative;
      text-align: left;
      gap: 16px;
      width: 80px;
      flex-shrink: 0;
      margin-right: 16px;
      &.user {
        color: #0099ff;
      }
      &.assistant {
        color: #009900;
      }
    }
    .speaker-content {
      color: #18181b;
      overflow: hidden;
      word-wrap: break-word;
    }
  }

  .event {
    border-radius: 3px;
    white-space: pre;
    display: flex;
    padding: 0px;
    gap: 16px;
    .event-timestamp {
      text-align: left;
      gap: 8px;
      padding: 4px 0px;
      width: 80px;
      flex-shrink: 0;
      margin-right: 16px;
    }
    .event-details {
      display: flex;
      flex-direction: column;
      color: #18181b;
      gap: 8px;
      .event-summary {
        padding: 4px 8px;
        margin: 0px -8px;
        &:hover {
          border-radius: 8px;
          background-color: #f0f0f0;
        }
        cursor: pointer;
        display: flex;
        gap: 8px;
        align-items: center;
        .event-source {
          flex-shrink: 0;
          display: flex;
          align-items: center;
          gap: 8px;
          &.client {
            color: #0099ff;
          }
          &.server {
            color: #009900;
          }
          &.error {
            color: #990000;
          }
          svg {
            stroke-width: 3;
            width: 12px;
            height: 12px;
          }
        }
      }
    }
  }

  // .visualization {
  //   // position: absolute;
  //   display: flex;
  //   bottom: 4px;
  //   right: 8px;
  //   padding: 4px;
  //   border-radius: 16px;
  //   z-index: 10;
  //   gap: 2px;
  //   .visualization-entry {
  //     position: relative;
  //     display: flex;
  //     align-items: center;
  //     height: 40px;
  //     width: 100px;
  //     gap: 4px;
  //     &.client {
  //       color: #0099ff;
  //     }
  //     &.server {
  //       color: #009900;
  //     }
  //     canvas {
  //       width: 100%;
  //       height: 100%;
  //       color: currentColor;
  //     }
  //   }
  // }
}
