[data-component='App'] {
  height: 100%;
  width: 100%;
  position: relative;
}

body {
  background: linear-gradient(135deg, #ff9a9e, #fad0c4, #fad0c4, #fbc2eb, #a1c4fd, #c2e9fb, #d4fc79, #96e6a1);
  background-size: cover;
  margin: 0;
  padding: 0;
}