@import url('https://fonts.googleapis.com/css2?family=Comic+Neue:wght@400;700&display=swap');

[data-component='ConsolePage'] {
  font-family: 'Comic Neue', monospace;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 0px 8px;
  & > div {
    flex-shrink: 0;
  }

  .dashboard-icon {
    width: 24px; /* Adjust the width as needed */
    height: 24px; /* Adjust the height as needed */
  }

  .ollie-image {
    width: 150px; /* Adjust the size as needed */
    height: auto; /* Maintain the aspect ratio */
    display: block;
    margin-right: 20px;
    margin-bottom: 20px;
  }

  .title-page {
    text-align: center;
    padding: 20px;
  }
  
  .content-title {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .image-links {
    display: flex;
    justify-content: center;
    gap: 40px;
    padding: 20px;
    flex-wrap: wrap; /* Ensure images wrap to the next line if needed */
  }
  
  .image-links img {
    max-width: 100%; /* Ensure the image fits within the container */
    height: auto; /* Maintain the aspect ratio */
    width: 250px; /* Set a fixed width for the images */
    border-radius: 40px;
    cursor: pointer;
    transition: transform 0.3s;
  }
  
  .image-links img:hover {
    transform: scale(1.1);
  }

  .image-container {
    text-align: center;
  }
.caption {
  font-family: 'Pacifico', cursive; /* Use the Pacifico font */
  margin-top: 10px;
  font-size: 2em;
  color: #333;
}

  .rainbow-text 
  {
    // margin-left: 2%;
    margin-bottom: 5%;
    background: linear-gradient(to right, #ff6600 10%, #800000 70%, #cc0000 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 3vw;
    font-family: 'Comic Neue', sans-serif;
    text-align: center;
      color: #000;
    text-align: center;
    background: linear-gradient(90deg, red, orange, yellow, green, blue, indigo, violet);

  // background: -webkit-linear-gradient(right, #ff6600 0%, #800000 25%, #cc0000 50%, yellow 100%) repeat;
    -webkit-background-clip: text;
    -ms-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -ms-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    text-fill-color: transparent;
    -webkit-animation-name: shimmer;
    -webkit-animation-duration: 50s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;

}
  // {
  //   font-family: 'Comic Neue';
  //   font-size: 4em; /* Adjust the size as needed */
  //   -webkit-background-clip: text;
  //   color: transparent;
  //   display: inline-block;
  //   animation: shimmer 2s linear infinite;
  // }

  @keyframes shimmer {
    0% {
        background-position: 0,0;
    }

    100% {
       background-position: 8000px 3000px;
    }
}

  .spacer {
    flex-grow: 1;
  }

  .content-top {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    min-height: 40px;
    .content-title {
      flex-grow: 1;
      display: flex;
      align-items: center;
      gap: 12px;
      img {
        width: 120px;
        height: 120px;
        display: block;
      }
    }
  }

  .content-main {
    flex-grow: 1;
    flex-shrink: 1 !important;
    margin: 0px 16px;
    display: flex;
    overflow: hidden;
    margin-bottom: 24px;
    .content-block {
      position: relative;
      display: flex;
      flex-direction: column;
      max-height: 100%;
      width: 100%;
      .content-block-title {
        flex-shrink: 0;
        padding-top: 16px;
        padding-bottom: 4px;
        position: relative;
      }
      .content-block-body {
        color: #6e6e7f;
        position: relative;
        flex-grow: 1;
        padding: 8px 0px;
        padding-top: 4px;
        line-height: 1.2em;
        overflow: auto;
        &.full {
          padding: 0px;
        }
        
      }
    }
    .content-right {
      width: 600px;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      margin-left: 24px;
      gap: 24px;
      overflow: hidden;
      & > div {
        border-radius: 16px;
        flex-grow: 1;
        flex-shrink: 0;
        overflow: hidden;
        position: relative;
        .content-block-title {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 2em;
          top: 16px;
          left: 16px;
          padding: 4px 16px;
          background-color: #fff;
          border-radius: 1000px;
          min-height: 32px;
          z-index: 9999;
          text-align: center;
          white-space: normal;
          &.bottom {
            top: auto;
            bottom: 16px;
            right: 16px;
          
          }
        }
      }
      & > div.kv {
        height: 250px;
        max-height: 250px;
        white-space: pre;
        background-color: #ececf1;
        .content-block-body {
          padding: 16px;
          margin-top: 56px;
          
        }
   
      }
    }
    .content-logs {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      & > div {
        flex-grow: 1;
      }
      & > .content-actions {
        flex-grow: 0;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;
      }
      & > div.events {
        overflow: hidden;
      }
      .events {
        border-top: 1px solid #e7e7e7;
      }
      .conversation {
        display: flex;
        flex-shrink: 0;
        width: 100%;
        overflow: hidden;
        height: 200px;
        min-height: 0;
        max-height: 200px;
        border-top: 1px solid #e7e7e7;
      }
    }
  }

  .conversation-item {
    position: relative;
    display: flex;
    gap: 16px;
    margin-bottom: 16px;
    &:not(:hover) .close {
      display: none;
    }
    .close {
      position: absolute;
      top: 0px;
      right: -20px;
      background: #aaa;
      color: #fff;
      display: flex;
      border-radius: 16px;
      padding: 2px;
      cursor: pointer;
      &:hover {
        background: #696969;
      }
      svg {
        stroke-width: 3;
        width: 12px;
        height: 12px;
      }
    }
    .speaker {
      position: relative;
      text-align: left;
      gap: 16px;
      width: 80px;
      flex-shrink: 0;
      margin-right: 16px;
      &.user {
        color: #0099ff;
      }
      &.assistant {
        color: #009900;
      }
    }
    .speaker-content {
      color: #18181b;
      overflow: hidden;
      word-wrap: break-word;
    }
  }

  .event {
    border-radius: 3px;
    white-space: pre;
    display: flex;
    padding: 0px;
    gap: 16px;
    .event-timestamp {
      text-align: left;
      gap: 8px;
      padding: 4px 0px;
      width: 80px;
      flex-shrink: 0;
      margin-right: 16px;
    }
    .event-details {
      display: flex;
      flex-direction: column;
      color: #18181b;
      gap: 8px;
      .event-summary {
        padding: 4px 8px;
        margin: 0px -8px;
        &:hover {
          border-radius: 8px;
          background-color: #f0f0f0;
        }
        cursor: pointer;
        display: flex;
        gap: 8px;
        align-items: center;
        .event-source {
          flex-shrink: 0;
          display: flex;
          align-items: center;
          gap: 8px;
          &.client {
            color: #0099ff;
          }
          &.server {
            color: #009900;
          }
          &.error {
            color: #990000;
          }
          svg {
            stroke-width: 3;
            width: 12px;
            height: 12px;
          }
        }
      }
    }
  }

  .visualization {
    position: absolute;
    display: flex;
    bottom: 4px;
    right: 8px;
    padding: 4px;
    border-radius: 16px;
    z-index: 10;
    gap: 2px;
    .visualization-entry {
      position: relative;
      display: flex;
      align-items: center;
      height: 40px;
      width: 100px;
      gap: 4px;
      &.client {
        color: #0099ff;
      }
      &.server {
        color: #009900;
      }
      canvas {
        width: 100%;
        height: 100%;
        color: currentColor;
      }
    }
  }

  .footer {
    background-color: rgb(205, 210, 212);
    padding: 10px;
    text-align: center;
    width: 50%;       /* Shrink the footer to half the page width */
    margin: 0 auto;   /* Center the footer */
    p {
      font-family: 'Open Sans', Arial, sans-serif;
      font-size: 0.8em;
      margin: 0;
      line-height: 1.4;
    }
  }

}
